@media only screen and (max-width: 576px) {
    footer, .about, .why-astudyhub, .scholarship-criteria {
        padding: 0px 10px;
    }
    .lable-slogan{
        padding: 0px 5px!important;
    }
    .single-cta{
        margin-top: 10px;
    }
    .pt-5{
        padding-top: 1rem !important;
    }
    .pb-5{
        padding-bottom: 1rem !important;
    }
    .footer-social-icon span{
        margin-bottom: 10px!important;
    }
    .footer-widget{
        margin-bottom: 10px;
    }
}

.mt-10{
    margin-top: 10px;
}
.mt-5{
    margin-top: 5px;
}

.back-to-top{
    position: fixed;
    bottom: 24px;
    right: 24px;
    font-size: 18px;
    background-color: #1c7049;
    color: #fff;
    border: none;
    outline: auto;
    border-radius: 50%;
    box-shadow: 0px 1px 3px #333;
    z-index: 9;
}

.back-to-top:hover{
    transition: ease-in-out .5s;
    background-color: #fff;
    color: #1c7049;
    font-weight: 600;
}


