
.scholarship-criteria ul li, .why-astudyhub ul li{
    text-transform: capitalize;
}
.scholarship-criteria .fa-check,.why-astudyhub .fa-check{
    color: #1c7049;
}
.scholarship-criteria marquee{
    color: #000;
}
