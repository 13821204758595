.navbar {
    padding: 0px 5px!important;
    box-shadow: 0px 8px 8px -6px rgba(0,0,0,.5);
    -webkit-box-shadow: 0px 8px 8px -6px rgba(0,0,0,.5);
    -moz-box-shadow: 0px 8px 8px -6px rgba(0,0,0,.5);
}
.mt-30{
    margin-top: 30px;
}
.header__topleftmenu li {
    float: left;
    padding: 0 10px;
    position: relative;
}
.header__topleftmenu a,.header__topleftmenu span {
    font: 14px/30px "Lato-Bold";
    color: #fff;
    letter-spacing: 1px;
}
ul:before, ul:after {
    content: "";
    display: table;
}
ul, ol {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-left: 0px!important;
}
.header_topmenu .col-sm-9, .header_topmenu .col-sm-3, .header_topmenu .col-sm-12{
    background: #1c7049;
    max-height: 30px;
    padding: 0px!important;
}
.header__language {
    float: right;
    padding: 2px 3px 0 1px;
}
.header__language li {
    float: left;
    padding: 0 5px;
    position: relative;
}
.header__language li:first-child {
    padding-left: 0;
}
.header__language li a, .header__language li span{
    color: #fff;
    font-size: 14px;
    letter-spacing: 1px;
    *font-weight: 500;
}
.header__toprightmenu .login-btn span{
    *border: 1px solid #fff;
    padding: 1px 10px;
    border-radius: 5px;
    cursor: pointer;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
    padding-right: 0px!important;padding-left: 0px!important;
}
.header_topmenu .row{
    --bs-gutter-x:0!important;
}
.nav-logo{
    max-width: 96px;
}